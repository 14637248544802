import React, { useState, useContext, useRef, useEffect } from 'react';
import {
    Row, Col, Form
} from 'react-bootstrap';
import '../../styles/computercraft.css'
import { WSContext } from '../contexts/WSContext';
import PropTypes from 'prop-types';



const ComputerCraft = (props) => {

    const {
        ws
    } = useContext(WSContext)

    const {
        consoleHistory,
        setConsoleHistory
    } = props;
    
    const [commandHistory, setCommandHistory] = useState([])
    const [currCommand, setCurrCommand] = useState()
    const [historyCursor, setHistoryCursor] = useState(0)
    const [computerId, setComputerId] = useState()

    const changeComputerId = (value) => {
        value = value.replace(/[^0-9]/g, '');
        if (value === '') {
            setComputerId('')
        }
        else {
            setComputerId(parseInt(value))
        }
    }

    const consoleHistoryRef = useRef(null)

    const clearOnRightClick = (event, field) => {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        event.preventDefault();
        if (field === 'command') {
            setCurrCommand("");
        }
        else if (field === 'computerId') {
            setComputerId("")
        }
    }

    const changeCommand = (commandText, keyPressed) => {
        if (keyPressed === "Enter") {
            setHistoryCursor(commandHistory.length + 1);
            setCommandHistory(() => [...commandHistory, commandText]);
            setConsoleHistory(() => [...consoleHistory, {type:"command", text:commandText}]);
            setCurrCommand("");
            // send command to server
            const checkCraftableCommand = { "type": "console", "context": { "command": `return ${commandText}` }, "targetId": `internal-${computerId}` };
            ws.send(JSON.stringify(checkCraftableCommand));
        } else if (keyPressed === "ArrowUp") {
            setCurrCommand(commandHistory[(historyCursor - 1 >= 0) ? historyCursor - 1 : historyCursor]);
            setHistoryCursor((historyCursor - 1 >= 0) ? historyCursor - 1 : historyCursor);

        } else if (keyPressed === "ArrowDown") {
            if (historyCursor + 1 < commandHistory.length) {
                setCurrCommand(commandHistory[historyCursor + 1])
                setHistoryCursor(historyCursor + 1);
            } else {
                setCurrCommand("")
                setHistoryCursor(commandHistory.length)
            }
        }
    }

    useEffect(() => {
        consoleHistoryRef.current.scrollTop = consoleHistoryRef.current.scrollHeight
    }, [consoleHistory])

    return (
        <>
            <Row>
                <Col>
                    <Form.Label bsPrefix="commandCarrot topCommand">{`target: >`}</Form.Label>
                    <Form.Control as="input"
                        value={computerId}
                        className="consoleCommand topCommand"
                        onChange={(e) => changeComputerId(e.target.value)}
                        onContextMenu={(e) => clearOnRightClick(e, 'computerId')}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Control as="textarea"
                        value={consoleHistory.map(command => (command.type === "command")?`> ${command.text}`:command.text).join("\n")}
                        ref={consoleHistoryRef}
                        disabled rows={6} className="consoleHistory"
                    />
                    <Form.Label bsPrefix="commandCarrot bottomCommand">{`>`}</Form.Label>
                    <Form.Control as="input"
                        value={currCommand}
                        className="consoleCommand bottomCommand"
                        onChange={(e) => setCurrCommand(e.target.value)}
                        onKeyUp={(e) => changeCommand(e.target.value, e.key)}
                        onContextMenu={(e) => clearOnRightClick(e, 'command')}
                    />
                </Col>
            </Row>
        </>
    );
}
export default ComputerCraft;


ComputerCraft.propTypes = {
    consoleHistory: PropTypes.shape({}).isRequired,
    setConsoleHistory: PropTypes.func.isRequired
}