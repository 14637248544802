import React, { useContext, useEffect, useState } from 'react';
import {
    Row, Col, Table, Form, Button, Modal
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../styles/computercraft.css'
import { WSContext } from '../contexts/WSContext';

const NetworkContents = (props) => {
    const { contentsHandler } = props

    const { ws } = useContext(WSContext)
    const [filteredContents, setFilteredContents] = useState();
    const [filter, setFilter] = useState();
    const [craftAmount, setCraftAmount] = useState();
    const [showCraftModal, setShowCraftModal] = useState(false);
    const [currentItem, setCurrentItem] = useState();

    const filterContents = (contents, filter) => {
        if (filter !== undefined) {
            contents = contents.filter(
                item => item.n.toLowerCase().includes(filter.toLowerCase())
            )
            setFilteredContents(contents);
        }
        else {
            setFilteredContents(contents)
        }
    }

    useEffect(() => {
        filterContents(contentsHandler, filter)
    }, [contentsHandler, filter])

    const changeCraftAmount = (value) => {
        value = value.replace(/[^0-9]/g, '');
        setCraftAmount(parseInt(value))
    }

    const handleCraftModalClose = () => {
        setShowCraftModal(false);
    }

    const handleShowCraftModal = (itemName, itemId) => {
        setCurrentItem({ itemName, itemId });
        setShowCraftModal(true);
    }

    const craftItem = (itemId, amount) => {
        const checkCraftableCommand = { "type": "storage", "context": { "command": "checkCraftable", "i": itemId, "a": amount }, "targetId": "internal-14" };
        ws.send(JSON.stringify(checkCraftableCommand));
        handleCraftModalClose()
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const getContentsCommand = { "type": "storage", "context": { "command": "getContents" }, "targetId": "internal-14" };
            ws.send(JSON.stringify(getContentsCommand));
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Row>
                <Col>
                    <Row><Col>
                        <Form.Control value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                        <br />
                    </Col></Row>
                    <Row>
                        <Col>
                            <Table bordered className='itemTable'>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredContents &&
                                        filteredContents.map((item) =>
                                            <React.Fragment>
                                                <tr className='col-md-6'>
                                                    <td>
                                                        {item.c === 'Y' &&
                                                            <Button className='craftButton' onClick={() => handleShowCraftModal(item.n, item.i)}>Craft</Button>
                                                        }
                                                        {` ${item.n}`}
                                                    </td>
                                                    <td>{item.a}</td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {currentItem &&
                <Modal show={showCraftModal}>
                    <Modal.Header>
                        <Modal.Title>Craft {currentItem.itemName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row><Col><p>Specify amount to craft:</p></Col></Row>
                        <Row>
                            <Col>
                                <Form.Control
                                    value={craftAmount}
                                    onChange={(e) => changeCraftAmount(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Button onClick={() => craftItem(currentItem.itemId, craftAmount)}>Craft</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleCraftModalClose}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}
export default NetworkContents;

NetworkContents.propTypes = {
    contentsHandler: PropTypes.shape({}),
}
