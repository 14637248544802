import React, { useState, useEffect } from 'react';
import {
    Row, Col, Accordion
} from 'react-bootstrap';
import '../../styles/computercraft.css'
import { WSContextProvider } from '../contexts/WSContext';
import NetworkContents from './NetworkContents';
import Console from './Console';
import useWebSocket from '../../hooks/useWebSocket';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ComputerCraft = (props) => {

    const { wsKey } = props;
    const {
        createWebsocket
    } = useWebSocket()

    const [contentsHandler, setContentsHandler] = useState()
    const [consoleHistory, setConsoleHistory] = useState([])
    const [ws, setWs] = useState(null)

    useEffect(() => {
        if (wsKey && !ws) {
            createWebsocket(wsKey).then((ws) => {
                const getContentsCommand = { "type": "storage", "context": { "command": "getContents" }, "targetId": "internal-14" };
                ws.send(JSON.stringify(getContentsCommand));

                ws.onmessage = event => {
                    try {
                        const eventData = JSON.parse(event.data);
                        console.log(eventData);
                        if (eventData.command === "handleCommand") {
                            setConsoleHistory(() => [...consoleHistory, { type: "result", text: eventData.results }]);
                        } else if (eventData.command === "getContents") {
                            const localStorageContents = []
                            eventData.results.forEach((elm) => {
                                localStorageContents[localStorageContents.length] = elm
                            })
                            localStorageContents.sort((a, b) => b.a - a.a)
                            setContentsHandler(localStorageContents)
                        } else if (eventData.command === "checkCraftable") {
                            if (eventData.type === "success") {

                                toast.success(eventData.results, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            } else {
                                toast.error(eventData.results, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }
                        }
                    }
                    catch (e) {
                        const eventData = event.data;
                        console.log(eventData);
                        console.log(e)
                    }
                }
                setWs(ws)
            });
        } else if (wsKey) {
            ws.onmessage = event => {
                try {
                    const eventData = JSON.parse(event.data);
                    console.log(eventData);
                    if (eventData.command === "handleCommand") {
                        setConsoleHistory(() => [...consoleHistory, { type: "result", text: eventData.results }]);
                    } else if (eventData.command === "getContents") {
                        const localStorageContents = []
                        eventData.results.forEach((elm) => {
                            localStorageContents[localStorageContents.length] = elm
                        })
                        localStorageContents.sort((a, b) => b.a - a.a)
                        setContentsHandler(localStorageContents)
                    } else if (eventData.command === "checkCraftable") {
                        if (eventData.type === "success") {

                            toast.success(eventData.results, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            toast.error(eventData.results, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                }
                catch (e) {
                    const eventData = event.data;
                    console.log(eventData);
                    console.log(e)
                }
            }
        }
    }, [consoleHistory, createWebsocket, ws, wsKey])

    return (
        <>
            {ws &&
                <> <WSContextProvider ws={ws}>
                    <Row>
                        <Col>
                            <Accordion>
                                <Accordion.Item eventKey='0'>
                                    <Accordion.Header>
                                        Network Contents
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {contentsHandler && ws &&
                                            <NetworkContents
                                                contentsHandler={contentsHandler}
                                            />
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='1'>
                                    <Accordion.Header>
                                        Console
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {consoleHistory && ws &&
                                            <Console
                                                consoleHistory={consoleHistory}
                                                setConsoleHistory={setConsoleHistory}
                                            />
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </WSContextProvider>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </>
            }
        </>
    );
}
export default ComputerCraft;

NetworkContents.propTypes = {
    wsKey: PropTypes.string.isRequired,
}
