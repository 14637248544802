/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Button, Collapse
} from 'react-bootstrap';
import Passworder from './passworder/Passworder';
import ServerStarter from './serverstarter/ServerStatus';
import MenuIcon from '@mui/icons-material/Menu';
import useFirebase from '../hooks/useFirebase'
import useDatabase from '../hooks/useDatabase';
import useAuth from '../hooks/useAuth';
import ComputerCraft from './computercraft/ComputerCraft';
import { WSContextProvider } from './contexts/WSContext';



function Layout() {

  const {
    app,
  } = useFirebase();

  const {
    auth,
    login,
    onAuthStateChanged,
  } = useAuth(app)

  const {
    webSocketSecrets,
    onValue,
  } = useDatabase();

  const [wsKey, setWsKey] = useState()

  useEffect(() => {
    onValue(webSocketSecrets, (snapshot) => {
      const data = snapshot.val();
      setWsKey(data.key)
    });

  }, [webSocketSecrets, onValue]);


  const [userLoggedIn, setUserLoggedIn] = useState(false);


  const [page, setPage] = useState(
    null
  )

  const handleAuthChange = (value) => {
    const updatedUserLoggedIn = value;
    setUserLoggedIn(updatedUserLoggedIn);
  }

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      handleAuthChange(true);
    } else {
      handleAuthChange(false);
    }
  });


  const [open, setOpen] = useState(false);

  const [menuButtonClass, setMenuButtonClass] = useState("menuButtonOpen");

  const handleMenuClose = (pageOption, open) => {
    setOpen(open);
    setPage(pageOption);
    if (open) {
      setMenuButtonClass("menuButtonOpen")
    }
    else {
      setMenuButtonClass("menuButtonClosed")
    }
    if (pageOption === 1) {
      window.location.href = "https://productionlist.kanekelley.com";
    }

  }

  const handleMenuOpen = (open) => {
    if (open) {
      setMenuButtonClass("menuButtonOpen")
    }
    else {
      setMenuButtonClass("menuButtonClosed")
    }

    setOpen(open)
  }

  return (
    <>{userLoggedIn &&
      <>
        <div className='d-grid'>
          <Button
            variant='secondary'
            onClick={() => handleMenuOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className={menuButtonClass}
          >
            <MenuIcon />
          </Button>
        </div>
        <Collapse id="example-collapse-text" className="test" in={open}>
          <div>
            <Row>
              <Col className='d-grid'>
                <Button variant="secondary" className="menuOptionButton" onClick={() => handleMenuClose(0, !open)}>Passworder</Button>
              </Col>
            </Row>
            <Row>
              <Col className='d-grid'>
                <Button variant="secondary" className="menuOptionButton" onClick={() => handleMenuClose(1, !open)}>Production List</Button>
              </Col>
            </Row>
            <Row>
              <Col className='d-grid'>
                <Button variant="secondary" className="menuOptionButton" onClick={() => handleMenuClose(2, !open)}>Server Starter</Button>
              </Col>
            </Row>
            <Row>
              <Col className='d-grid'>
                <Button variant="secondary" className="menuOptionButton menuOptionButtonLast" onClick={() => handleMenuClose(3, !open)}>Computer Craft</Button>
              </Col>
            </Row>
          </div>
        </Collapse>
      </>

    }
      <Container fluid>
        {!userLoggedIn ?
          (
            <Row id="login">
              <Col>
                <Button id="login-button" variant="secondary" onClick={() => login()}>Login</Button>
              </Col>
            </Row>
          ) :
          (
            <>
              <br />
              {page === 0 &&
                <Passworder />
              }
              {page === 2 &&
                <ServerStarter />
              }
              {page === 3 && wsKey &&
                  <ComputerCraft
                    wsKey={wsKey}
                  />
              }
            </>
          )
        }

      </Container>
    </>
  );
}

export default Layout;