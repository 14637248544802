import React, { useState } from 'react';
import {
    Row, Col, Button, Modal, Form,
} from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';


function KeyEdit(props) {
    const {
        keyName,
        algorithm,
        passLength,
        useSymbols,
        customPass,
        show,
        handleClose,
        handleDelete,
        performCalculate,
        readOnlyName,
    } = props;

    const [fields, setFields] = useState({
        keyName,
        algorithm,
        passLength,
        useSymbols,
        customPass,
    })




    const handleChange = (field, value) => {
        setFields({
            ...fields,
            [field]: value
        })
    }

    const handleCheckChange = (target, field, value) => {
        setFields({
            ...fields,
            [field]: target.checked
        })

        target.checked = !(target.checked);

    }

    const [showCustom, setShowCustom] = useState(
        true
    );

    const [showLength, setShowLength] = useState(
        true
    );

    const [showSymbols, setShowSymbols] = useState(
        true
    );

    const [calculatedPass, setCalculatedPass] = useState(
        ""
    );


    const algOptions = [
        { value: 0, label: 'Dictionary' },
        { value: 1, label: 'Original' },
        { value: 2, label: 'Custom' },
    ]

    const changeAlg = (option) => {
        algorithm.key = option.label;
        switch (option.value) {
            case 0:
                setShowCustom(false);
                setShowLength(false);
                setShowSymbols(true);
                break;
            case 1:
                setShowCustom(false);
                setShowLength(true);
                setShowSymbols(false);
                break;
            case 2:
                setShowCustom(true);
                setShowLength(false);
                setShowSymbols(false);
                break;
            default:
                break;
        }
        handleChange("algorithm", { "key": option.label, "value": option.value })
    }

    const handleCalculate = () => {

        changeAlg({ label: fields.algorithm.key, value: fields.algorithm.value })

        if (fields.keyName) {
            performCalculate(fields.keyName, fields.algorithm.value,
                fields.useSymbols, fields.passLength, fields.customPass)
                .then((value) => {
                    setCalculatedPass(value);
                    copyPass(value);
                });
        }


    }

    const copyPass = (value) => {
        navigator.clipboard.writeText(value);
    }

    return (
        <Modal
            onShow={handleCalculate}
            onHide={() => handleClose(fields)}
            show={show}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                {handleDelete &&
                    <Button variant="danger" onClick={() => handleDelete(fields.keyName)}>
                        <DeleteIcon />
                    </Button>
                }
                <Modal.Title>Key Management</Modal.Title>
                <Button variant="secondary" onClick={() => handleClose(fields)}>
                    <CloseIcon />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <span>
                            Key
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control
                            type="text"
                            value={fields.keyName}
                            readOnly={readOnlyName}
                            onChange={(e) => handleChange('keyName', e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span>
                            Algorithm
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Select
                            placeholder={algorithm.key}
                            value={algorithm.value}
                            onChange={(option) => changeAlg(option)}
                            options={algOptions}
                            classNamePrefix="react-select"
                        />

                    </Col>
                </Row>
                {showLength &&
                    <>
                        <Row>
                            <Col>
                                <span>
                                    Pass Length
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control
                                    type="number"
                                    value={fields.passLength}
                                    onChange={(e) => handleChange('passLength', e.target.value)}
                                />
                            </Col>
                        </Row>
                    </>
                }
                {showSymbols &&
                    <>
                        <Row>
                            <Col>
                                <span>
                                    Use Symbols
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Check
                                    value={fields.useSymbols}
                                    checked={fields.useSymbols}
                                    onChange={(e) => handleCheckChange(e.target, 'useSymbols', e.target.value)}
                                />
                            </Col>
                        </Row>
                    </>
                }
                {showCustom &&
                    <>
                        <Row>
                            <Col>
                                <span>
                                    Custom Password
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control
                                    type="text"
                                    value={(fields.customPass === "") ? "" : fields.customPass}
                                    checked={(fields.customPass === 'true')}
                                    onChange={(e) => handleChange('customPass', e.target.value)}
                                />
                            </Col>
                        </Row>
                    </>
                }
                <Row>
                    <Col>
                        <Button onClick={handleCalculate}>Calculate Pass</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>{calculatedPass}</p>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
export default KeyEdit;

KeyEdit.propTypes = {
    keyName: PropTypes.string,
    algorithm: PropTypes.shape({}),
    passLength: PropTypes.number,
    useSymbols: PropTypes.bool,
    customPass: PropTypes.string,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func,
    performCalculate: PropTypes.func.isRequired,
    readOnlyName: PropTypes.bool.isRequired,
}
