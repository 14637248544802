import React from 'react';
import PropTypes from 'prop-types';

const WSContext = React.createContext({});

const WSContextProvider = (props) => {
  const { ws, sendWhenReady, children } = props;

  return (
    <WSContext.Provider value={{
      ws, sendWhenReady
    }}
    >
      {children}
    </WSContext.Provider>
  );
};

export {
  WSContext,
  WSContextProvider,
};

WSContextProvider.propTypes = {
  ws: PropTypes.shape({}).isRequired,
  sendWhenReady: PropTypes.func.isRequired.apply,
  children: PropTypes.node.isRequired,
};
