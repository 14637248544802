
import { useCallback } from 'react';

const useWebSocket = () => {
  const createWebsocket = useCallback((apiKey) => {
    const ws = new WebSocket(`wss://m.ethanluckett.com:5001/minecraft/websocket/1155445?apiKey=${apiKey}`);
    return new Promise((resolve, reject) => {
      ws.addEventListener('open', (event) => resolve(ws));
    });
  }, []);

  const waitForSocketConnection = (socket) => {
    setTimeout(
      () => {
        if (socket.readyState === 1) {
          console.log("Connection is made")
        } else {
          console.log("wait for connection...")
          waitForSocketConnection(socket);
        }

      }, 5); // wait 5 milisecond for the connection...
  }

  return {
    createWebsocket, waitForSocketConnection
  }
}

export default useWebSocket;