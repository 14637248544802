import React, { useState, useEffect, } from 'react';
import {
  Row, Col, Button
} from 'react-bootstrap';
import { EC2Client, StopInstancesCommand, StartInstancesCommand, DescribeInstanceStatusCommand, } from "@aws-sdk/client-ec2";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import useDatabase from '../../hooks/useDatabase';


function ServerStarter() {

  const {
    awsSecrets,
    onValue,
  } = useDatabase();


  const [clientState, setClientState] = useState(null)

  const refreshStatus = (client, instanceObject) => {
    const command = new DescribeInstanceStatusCommand({ ...instanceObject, IncludeAllInstances: true });

    client.send(command).then((data) => {
      setInstanceStatus(data["InstanceStatuses"][0]["InstanceState"]["Name"]);
    },
      (error) => {
        console.log(error)
      });
  };

  useEffect(() => {
    onValue(awsSecrets, (snapshot) => {
      const data = snapshot.val();
      const __client = new EC2Client({
        region: "us-east-1",
        credentials: {
          accessKeyId: data.key,
          secretAccessKey: data.secret,
        }
      })
      const __instanceObject = { InstanceIds: [data.instanceId] }
      setClientState({ client: __client, instanceObject: __instanceObject})
      
      refreshStatus(__client, __instanceObject)
    });

  }, [awsSecrets, onValue]);



  const [instanceStatus, setInstanceStatus] = useState(
    null
  )

  let command = null;

  const startStopServer = (startOrStop) => {


    if (startOrStop) {
      command = new StartInstancesCommand(clientState.instanceObject);
    }
    else {
      command = new StopInstancesCommand(clientState.instanceObject)
    }

    clientState.client.send(command).then((data) => {
      refreshStatus(clientState.client, clientState.instanceObject);
    },
      (error) => {
        console.log(error)
      });
  };



  return (
    <>
      {
        clientState &&
        <>
          <Row>
            <Col><IconButton color='primary' onClick={() => refreshStatus(clientState.client, clientState.instanceObject)}><RefreshIcon /></IconButton>&nbsp;&nbsp;Server status: {instanceStatus}</Col>
          </Row>
          <br />
          <Row>
            <Col className='d-grid'>
              <Button variant="primary" onClick={() => startStopServer(true)}>Start Server</Button>
            </Col>
            <Col className='d-grid'>
              <Button variant="danger" onClick={() => startStopServer(false)}>Stop Server</Button>
            </Col>
          </Row>
        </>
      }
    </>
  );
}
export default ServerStarter;