/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    Container, Row, Col, Button, Collapse, Form
} from 'react-bootstrap';
import Key from './Key';
import KeyEdit from './KeyEdit';
import useFirebase from '../../hooks/useFirebase'
import useDatabase from '../../hooks/useDatabase';
import useFunctions from '../../hooks/useFunctions';

function Passworder() {

    const {
        app,
    } = useFirebase();


    const {
        database,
        keyRef,
        set,
        ref,
        onValue,
    } = useDatabase();

    const {
        performCalculate, performAddUserRole
    } = useFunctions(app)


    //do a blank performCalculate to have the cloud function ready
    performCalculate('', 0,
        true, 15, '')
        .then((value) => {
        });


    const [keys, setKeys] = useState(
        null
    )

    const [filteredKeys, setFilteredKeys] = useState(
        null
    )

    const [showNew, setShowNew] = useState(
        false
    )

    const handleCloseNew = (fields) => {
        setShowNew(false);
        fields.passLength = parseInt(fields.passLength)
        if (fields.keyName) {
            set(ref(database, 'keys/' + fields.keyName), {
                algorithm: fields.algorithm.value,
                customPass: fields.customPass,
                passLength: fields.passLength,
                useSymbols: fields.useSymbols
            });
        }
    }



    useEffect(() => {
        onValue(keyRef, (snapshot) => {
            const data = snapshot.val();
            setKeys(data);
            setFilteredKeys(data)
        });

    }, [keyRef, onValue]);

    const filterList = (filter) => {
        try{

            const keyFiltering = Object.keys(keys)
            .filter(key => key.toLowerCase().match(filter.toLowerCase()))
            .reduce((obj, key) => {
                obj[key] = keys[key];
                return obj;
            }, {});
            setFilteredKeys(keyFiltering)
        }
        catch{
            const keyFiltering = Object.keys(keys)
            .filter(key => key.toLowerCase().includes(filter.toLowerCase()))
            .reduce((obj, key) => {
                obj[key] = keys[key];
                return obj;
            }, {});
            setFilteredKeys(keyFiltering)
        }
        
    }

    return (
        <>
            {filteredKeys &&
                <>
                    {
                        Object.entries(filteredKeys).sort((a, b) => {
                            return a[0].toLowerCase().localeCompare(b[0].toLowerCase());}).map(([key, value]) =>
                            <Key
                                key={key}
                                database={database}
                                keyName={key}
                                algorithm={value.algorithm}
                                passLength={value.passLength}
                                useSymbols={value.useSymbols}
                                customPass={value.customPass}
                                performCalculate={performCalculate}
                            />
                            // console.log(key + " " + typeof value.passLength)
                        )
                    }
                    <br />
                    <br />
                    <br />
                    <Row>
                        <div className="bottomDiv">
                            <Row>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => filterList(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='d-grid gap-2'>
                                        <Button className="passButtons" onClick={() => setShowNew(true)}>New Key</Button>
                                    </div>
                                    <KeyEdit
                                        show={showNew}
                                        handleClose={handleCloseNew}
                                        keyName={""}
                                        algorithm={{ key: "Dictionary", value: 0 }}
                                        passLength={15}
                                        useSymbols={true}
                                        customPass={""}
                                        performCalculate={performCalculate}
                                        readOnlyName={false}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </>
            }
            {/* {
                <div>
                    <input type="checkbox" id="adminCheck"></input>
                    <input type="checkbox" id="userCheck"></input>
                    <input id="login-email"></input>
                    <button onClick={performAddUserRole}></button>

                </div>
            } */}
        </>
    );
}
export default Passworder;