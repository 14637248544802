import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyAb5xUCDLoXWu9ftE5t2PaowtM17bH4-Ag',
    authDomain: 'passworder-ece82.firebaseapp.com',
    databaseURL: 'https://passworder-ece82-default-rtdb.firebaseio.com',
    projectId: 'passworder-ece82',
    storageBucket: 'passworder-ece82.appspot.com',
    messagingSenderId: '145334049147',
    appId: '1:145334049147:web:f5485a5191ef52983dc5de',
    measurementId: 'G-FL4900SV8S',
};

// Initialize Firebase
// eslint-disable-next-line no-unused-vars

const app = initializeApp(firebaseConfig);



// eslint-disable-next-line max-len

// eslint-disable-next-line no-unused-vars





function useFirebase() {
    return {
        app,
    }
}

export default useFirebase