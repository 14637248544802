import React, { useState } from 'react';
import {
  Row, Col, Button
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import KeyEdit from './KeyEdit';
import { set, ref, remove } from 'firebase/database';
import '../../styles/pass.css';

function Key(props) {
  const {
    keyName,
    algorithm,
    passLength,
    useSymbols,
    customPass,
    performCalculate,
    database,
  } = props;

  const [show, setShow] = useState(false);

  const getAlgObject = (algorithm) => {
    if (algorithm === 2) {
      return { key: "Custom", value: 2 }
    }
    else if (algorithm === 1) {
      return { key: "Original", value: 1 }
    }

    else {
      return { key: "Dictionary", value: 0 }
    }
  }

  const handleClose = (fields) => {
    setShow(false);
    fields.passLength = parseInt(fields.passLength)

    if (fields.keyName) {
      set(ref(database, 'keys/' + fields.keyName), {
        algorithm: fields.algorithm.value,
        customPass: fields.customPass,
        passLength: fields.passLength,
        useSymbols: fields.useSymbols
      });
    }
  }

  const handleDelete = (keyName) => {
    setShow(false);

    remove(ref(database, 'keys/' + keyName));
  }

  return (
    <>
      <Row>
        <Col>
          <div className='d-grid passButtonsGrid'>
            <Button className="passButtons" variant="outline-secondary" onClick={() => setShow(true)}><center>{keyName}</center></Button>
          </div>
        </Col>
      </Row>
      <KeyEdit
        key={keyName}
        show={show}
        handleClose={handleClose}
        handleDelete={handleDelete}
        keyName={keyName}
        algorithm={getAlgObject(algorithm)}
        passLength={passLength}
        useSymbols={useSymbols}
        customPass={customPass}
        performCalculate={performCalculate}
        readOnlyName={true}
      />
    </>
  );
}
export default Key;

Key.propTypes = {
  keyName: PropTypes.string.isRequired,
  passLength: PropTypes.number.isRequired,
  useSymbols: PropTypes.bool.isRequired,
  customPass: PropTypes.string,
  performCalculate: PropTypes.func.isRequired,
  database: PropTypes.shape({}).isRequired,
}

Key.defaultProps = {
  customPass: "",
}