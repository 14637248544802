/* eslint-disable no-unused-vars */
import {
    getAuth, signInWithPopup, GoogleAuthProvider, setPersistence,
    indexedDBLocalPersistence, onAuthStateChanged
} from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { useEffect } from 'react';

const auth = getAuth();

const provider = new GoogleAuthProvider();
setPersistence(auth, indexedDBLocalPersistence).then(
    () => {
    },
);

function login() {
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token.
            // You can use it to access the Google API.
            const credential = GoogleAuthProvider
                .credentialFromResult(result);
            // const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
}



function useAuth(app) {

    useEffect(() => {
        // global.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
        const appCheck = initializeAppCheck(app, {
            // eslint-disable-next-line max-len
            provider: new ReCaptchaV3Provider('6Lfas3AdAAAAABYmrlY8vWvPZ1-TSHdkKr2sgzAf'),

            // Optional argument. If true, the SDK automatically refreshes App Check
            // tokens as needed.
            isTokenAutoRefreshEnabled: true,
        });

    }, [app]);

    return {
        auth,
        login,
        onAuthStateChanged,
    }
}

export default useAuth

