import { getDatabase, ref, onValue, set } from 'firebase/database';

const database = getDatabase();

const keyRef = ref(database, 'keys');

const awsSecrets = ref(database, 'aws');

const webSocketSecrets = ref(database, 'ws');

function useDatabase() {

    return {
        database,
        keyRef,
        awsSecrets,
        webSocketSecrets,
        set,
        ref,
        onValue,
    }
}

export default useDatabase
