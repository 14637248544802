import { getFunctions, httpsCallable } from 'firebase/functions';
import { useCallback, } from 'react';

function useFunctions(app) {

    const performCalculate = useCallback(
        (key, alg, symbols, keyLength, customPass) => {
            const functions = getFunctions(app);
            const calculatePass = httpsCallable(functions, 'calculatePass');
            return calculatePass({
                key,
                alg,
                symbols,
                keyLength,
                customPass,
            }).then((result) => {
                const data = result.data;
                const sanitizedMessage = data.pass;
                return sanitizedMessage;

            }).catch((error) => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log(code);
                console.log(message);
                console.log(details);
            });
        }, [app]);

    const performAddUserRole = useCallback(
    () => {
        const adminCheck = document.getElementById('adminCheck').checked;
        const userCheck = document.getElementById('userCheck').checked;
        const email = document.getElementById('login-email').value;

        const functions = getFunctions(app);
        const calculatePass = httpsCallable(functions, 'addUserRole');
        calculatePass({
            adminCheck,
            userCheck,
            email,
        }).then((result) => {
            const data = result.data;
            console.log(data)
            // const sanitizedMessage = data.message;
        }).catch((error) => {
            const code = error.code;
            const message = error.message;
            const details = error.details;
            console.log(code);
            console.log(message);
            console.log(details);
        });
    }, [app]);


    return {
        performCalculate,
    }
}

export default useFunctions;